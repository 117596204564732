import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";

export default class About extends Component {
  render() {
    let content = `<p>Click speed test is an effective way to measure your clicks per second (CPS) rate. You can also use the tool to improve your score over time as you work on your click speed.</p>
      <p>Click speed is critical when playing computer games or video games. How fast you are able to click affects your ability to fight, ride, construct, when playing games like Minecraft. Furthermore, long hours of gaming, especially with your hand on the mouse in the same position can reduce your click time and impair your in-game performance.</p>
      <p>Therefore, it is important to try different techniques to improve your CPS. With the click speed test you can accurate test your results and track your progress.</p>
      <p>The click speed test uses the technology of the Element speed application to successfully, globally and accurately calculate your mouse click speed in clicks per second (CPS). This tool can also be used to measure clicks on a keyboard and calculate the corresponding rate.</p>
      <p>The goal of the click speed test is to help users to accurately measure their mouse click speed. The test can be taken repeatedly, and so users can use it to track their progress and therefore improve their click speed over time. This assists users to boost their performance in a range of computer-based tasks, particularly gaming. With lightning quick click speeds, gamers will be able fight, ride and build much more quickly, making them more successful.</p>
      <p>The click speed test is available in different test speeds. The default test speed is 5 seconds, which can be increased to click per 10 seconds, click per 1 minute and maximum click in 100 seconds.&nbsp;</p>
      <p>After taking the test, your result will be shown in the CPS counter, indicating your click speed. You can then share your results on social media such as Facebook and Twitter, to show your friends your amazing click speed!</p>`;
    return (
      <div className="container" style={{ marginTop: "6rem" }}>
        <Helmet>
          <title>About the click speed test</title>
          <meta
            name="description"
            content="The click speed test is an efficient, accurate and easy-to-use tool to measure your clicks per second, to track your progress and improve your mouse click rate."
          />
        </Helmet>
        <div className="about">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h1>About us</h1>
              {ReactHtmlParser(content)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
