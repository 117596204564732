import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import "./assets/scss/style.scss";
import {
  Navigation,
  ResultModal,
  Container,
  About,
  Footer,
  Error,
} from "./components";
import {
  clickTest10,
  clickTest15,
  clickTest30,
  clickTest60,
  clickTest100,
  clickTest,
  jitterClickTest,
  kohiClickTest,
} from "./contents";

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      second: 5,
      timeElapsed: 0,
      clickPerSecond: 0,
      score: 0,
      scoreToResult: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.second !== prevState.second) {
      if (this.state.score != 0) this.emergencyStopTimer();
    }
  }

  setTimer = (second) => {
    this.setState({
      second: second,
    });
  };

  incrementClick = () => {
    if (this.state.score === 0) {
      this.startTimer();
    }
    let score = this.state.score + 1;
    let clickPerSecond = (score / this.state.second).toPrecision(3);
    this.setState({
      score: score,
      clickPerSecond: clickPerSecond,
    });
  };

  startTimer = () => {
    this.timer = setInterval(this.update, 1000);
  };

  stopTimer = () => {
    clearInterval(this.timer);
    this.setState({
      scoreToResult: this.state.score,
    });
    this.toggleModal();
    this.setState({
      score: 0,
    });
  };

  emergencyStopTimer = () => {
    clearInterval(this.timer);
    this.setState({
      score: 0,
      timeElapsed: 0,
      clickPerSecond: 0,
    });
  };

  toggleModal = () => {
    if (this.state.showModal) {
      this.setState({
        timeElapsed: 0,
        clickPerSecond: 0,
      });
    }
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  update = () => {
    this.setState({
      timeElapsed: this.state.timeElapsed + 1,
    });

    if (this.state.timeElapsed === this.state.second) {
      this.stopTimer();
    }
  };

  render() {
    let seconds = this.state.timeElapsed;
    let milliSeconds = this.state.timeElapsed;
    return (
      <Router>
        <div className="App">
          <Helmet>
            <title>Improve your click speed with the click speed test</title>
            <meta
              name="description"
              content="The click speed test allows you to easily and accurately test your mouse click speed, to speed up your mouse click and improve your gaming performance."
            />
          </Helmet>

          {this.state.showModal ? (
            <ResultModal
              clickPerSecond={this.state.clickPerSecond}
              score={this.state.scoreToResult}
              second={this.state.second}
              toggleModal={() => this.toggleModal()}
            />
          ) : null}
          <Navigation emergencyStopTimer={this.emergencyStopTimer} />
          <Switch>
            <Route
              path="/"
              exact
              component={() => (
                <div>
                  <Container
                    second={this.state.second}
                    seconds={seconds}
                    milliSeconds={milliSeconds}
                    clickPerSecond={this.state.clickPerSecond}
                    score={this.state.score}
                    incrementClick={() => this.incrementClick()}
                    setTimer={(second) => this.setTimer(second)}
                    test={"normal"}
                  />

                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div class="card">
                          <div class="card-body">
                            {ReactHtmlParser(clickTest.content)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />

            <Route
              path="/10-second-click-test"
              exact
              component={() => (
                <div>
                  <Container
                    second={this.state.second}
                    seconds={seconds}
                    milliSeconds={milliSeconds}
                    clickPerSecond={this.state.clickPerSecond}
                    score={this.state.score}
                    incrementClick={() => this.incrementClick()}
                    setTimer={(second) => this.setTimer(second)}
                    test={"10-second-click-test"}
                  />

                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div class="card">
                          <div class="card-body">
                            {ReactHtmlParser(clickTest10.content)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />

            <Route
              path="/15-second-click-test"
              exact
              component={() => (
                <div>
                  <Container
                    second={this.state.second}
                    seconds={seconds}
                    milliSeconds={milliSeconds}
                    clickPerSecond={this.state.clickPerSecond}
                    score={this.state.score}
                    incrementClick={() => this.incrementClick()}
                    setTimer={(second) => this.setTimer(second)}
                    test={"15-second-click-test"}
                  />

                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div class="card">
                          <div class="card-body">
                            {ReactHtmlParser(clickTest15.content)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />

            <Route
              path="/30-second-click-test"
              exact
              component={() => (
                <div>
                  <Container
                    second={this.state.second}
                    seconds={seconds}
                    milliSeconds={milliSeconds}
                    clickPerSecond={this.state.clickPerSecond}
                    score={this.state.score}
                    incrementClick={() => this.incrementClick()}
                    setTimer={(second) => this.setTimer(second)}
                    test={"30-second-click-test"}
                  />

                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div class="card">
                          <div class="card-body">
                            {ReactHtmlParser(clickTest30.content)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />

            <Route
              path="/60-second-click-test"
              exact
              component={() => (
                <div>
                  <Container
                    second={this.state.second}
                    seconds={seconds}
                    milliSeconds={milliSeconds}
                    clickPerSecond={this.state.clickPerSecond}
                    score={this.state.score}
                    incrementClick={() => this.incrementClick()}
                    setTimer={(second) => this.setTimer(second)}
                    test={"60-second-click-test"}
                  />

                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div class="card">
                          <div class="card-body">
                            {ReactHtmlParser(clickTest60.content)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />

            <Route
              path="/100-second-click-test"
              exact
              component={() => (
                <div>
                  <Container
                    second={this.state.second}
                    seconds={seconds}
                    milliSeconds={milliSeconds}
                    clickPerSecond={this.state.clickPerSecond}
                    score={this.state.score}
                    incrementClick={() => this.incrementClick()}
                    setTimer={(second) => this.setTimer(second)}
                    test={"100-second-click-test"}
                  />

                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div class="card">
                          <div class="card-body">
                            {ReactHtmlParser(clickTest100.content)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />

            <Route
              path="/jitter-click-test"
              exact
              component={() => (
                <div>
                  <Container
                    second={this.state.second}
                    seconds={seconds}
                    milliSeconds={milliSeconds}
                    clickPerSecond={this.state.clickPerSecond}
                    score={this.state.score}
                    incrementClick={() => this.incrementClick()}
                    setTimer={(second) => this.setTimer(second)}
                    test={"jitter"}
                  />

                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div class="card">
                          <div class="card-body">
                            {ReactHtmlParser(jitterClickTest.content)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
            <Route
              path="/kohi-click-test"
              exact
              component={() => (
                <div>
                  <Container
                    second={this.state.second}
                    seconds={seconds}
                    milliSeconds={milliSeconds}
                    clickPerSecond={this.state.clickPerSecond}
                    score={this.state.score}
                    incrementClick={() => this.incrementClick()}
                    setTimer={(second) => this.setTimer(second)}
                    test={"kohi"}
                  />
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div class="card">
                          <div class="card-body">
                            {ReactHtmlParser(kohiClickTest.content)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
            <Route path="/about-us" exact component={() => <About />} />
            <Redirect from="/sitemap.xml" to="/sitemap.xml" />

            <Route path="/404" component={Error} />
            <Redirect from="*" to="/404" />
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}
