import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { RightSidebar, NotificationArea, ClickArea, ResultArea } from "./";

class Container extends Component {
  componentDidMount() {
    if (
      this.props.test === "10-second-click-test" &&
      this.props.second !== 10
    ) {
      this.props.setTimer(10);
    } else if (
      this.props.test === "15-second-click-test" &&
      this.props.second !== 15
    ) {
      this.props.setTimer(15);
    } else if (
      this.props.test === "30-second-click-test" &&
      this.props.second !== 30
    ) {
      this.props.setTimer(30);
    } else if (
      this.props.test === "60-second-click-test" &&
      this.props.second !== 60
    ) {
      this.props.setTimer(60);
    } else if (
      this.props.test === "100-second-click-test" &&
      this.props.second !== 100
    ) {
      this.props.setTimer(100);
    }
  }

  render() {
    return (
      <div class="container" style={{ marginTop: "6rem" }}>
        {this.props.test === "10-second-click-test" ? (
          <Helmet>
            <title>The 10 second click test: The industry standard</title>
            <meta
              name="description"
              content="The 10 second click tests allows you to accurately test your click speed, and get the best possible result as your finger won’t become strained or tired.
              "
            />
          </Helmet>
        ) : this.props.test === "15-second-click-test" ? (
          <Helmet>
            <title>The 15 second click test</title>
            <meta
              name="description"
              content="Take the 15 second click test to check your performance while working on the computer or playing games and use the result to determine your CPS rate."
            />
          </Helmet>
        ) : this.props.test === "30-second-click-test" ? (
          <Helmet>
            <title>The 30 second click test</title>
            <meta
              name="description"
              content="Test your progress by measuring your mouse click speed and help to improve your click speed through jitter clicking, butterfly clicking or drag clicking."
            />
          </Helmet>
        ) : this.props.test === "60-second-click-test" ? (
          <Helmet>
            <title>The 60 second click test</title>
            <meta
              name="description"
              content="You can use the 60 second click test on either your computer or smart phone and either with your mouse or your keyboard to find out your click speed."
            />
          </Helmet>
        ) : this.props.test === "100-second-click-test" ? (
          <Helmet>
            <title>The 100 second click test: The long-form test</title>
            <meta
              name="description"
              content="The longest and most comprehensive click speed test, the 100 second click test can help you improve your click speed on mouse or keyboard."
            />
          </Helmet>
        ) : this.props.test === "jitter" ? (
          <Helmet>
            <title>Advanced click techniques: The Jitter Click Test</title>
            <meta
              name="description"
              content="Jitter clicking can greatly improve click rates up to 14 CPS. Practice your jitter click technique and improve your performance with the jitter click test.
              "
            />
          </Helmet>
        ) : this.props.test === "kohi" ? (
          <Helmet>
            <title>
              Improve your Minecraft performance with the Kohi click test
            </title>
            <meta
              name="description"
              content="Click speeds are critical when playing Minecraft: faster click speeds mean you can fight, mine and build faster. Test your click speed with the Kohi speed test."
            />
          </Helmet>
        ) : null}

        <div class="row">
          <div class="col-md-8">
            <div class="card mb-4">
              <div class="card-body">
                <NotificationArea
                  second={this.props.second}
                  test={this.props.test}
                />
              </div>
            </div>
            <div class="card mb-4">
              <div class="card-body">
                <ResultArea
                  second={this.props.second}
                  seconds={this.props.seconds}
                  clickPerSecond={this.props.clickPerSecond}
                  score={this.props.score}
                />
              </div>
            </div>

            <div class="card mb-4">
              <div class="card-body">
                <ClickArea incrementClick={() => this.props.incrementClick()} />
              </div>
            </div>
          </div>
          {this.props.test === "normal" ||
          this.props.test === "10-second-click-test" ||
          this.props.test === "15-second-click-test" ||
          this.props.test === "30-second-click-test" ||
          this.props.test === "60-second-click-test" ||
          this.props.test === "100-second-click-test" ? (
            <div class="col-md-4">
              <RightSidebar
                setTimer={(second) => this.props.setTimer(second)}
                test={this.props.test}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default withRouter(Container);
