import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default class Error extends Component {
  render() {
    return (
      <div className="container" style={{ marginTop: "6rem" }}>
        <Helmet>
          <title>404 page</title>
          <meta name="description" content="Page not found" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="404" style={{ height: "50vh" }}>
          <div
            class="page-wrap d-flex flex-row align-items-center"
            style={{ paddingTop: "6rem" }}
          >
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-12 text-center">
                  <span class="display-1 d-block">404</span>
                  <div class="mb-4 lead">
                    The page you are looking for was not found.
                  </div>
                  <Link to="/" class="btn btn-link">
                    Back to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
