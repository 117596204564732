let clickTest = {
  content: `<h2>About the click speed test</h2>
    <p>Clickspeedtest.co calculates your mouse click speed, helping you calculate your mouse speed and ability. The system works by measuring the number of clicks within a certain length of time, giving you an overall score for your mouse click speed, or CPS rate.</p>
    <p>Taking click speed tests will help you to improve your click speed, and become more efficient in your work and perform better when gaming. The average mouse click time is around 60 seconds. If you are faster than this that means that your mouse click performance is above average.&nbsp;</p>
    <p>Simply take the test, clicking as fast and as often as you can. The higher the number of hits, the greater your score for your CPS rate will be.</p>
    <h2>How to take the click speed test</h2>
    <p>There are two main ways to take the click speed test. You can take it using your mouse or your keyboard. In both cases, it is extremely easy to take the click speed test for accurate results.</p>
    <p>The application uses Element Speed to calculate the number of clicks per second. This can be used from anywhere in the world for the same, accurate results.</p>
    <p>Here is a step-by-step guide to taking the click speed test:</p>
    <ol>
        <li>Launch the click test and click as much as you can using either your mouse or keyboard</li>
        <li>Click as quickly as you can within the 10-second time frame for the test</li>
        <li>At the end of the 10-second test, your CPS (clicks per second) score will be displayed</li>
        <li>Take your score and multiple it by six to find out your number of clicks per minute</li>
        <li>Check your click rate per second by dividing the rate determined in step #3 by 60</li>
        <li>To take the test again and try for a higher score, simply click on &ldquo;Restart Test&rdquo;</li>
    </ol>
    <p>There are no limits, so you can take the test as many times as you want to get the perfect score.&nbsp;</p>
    <p>You can also use the Auto Clicker for games, and double click to achieve a faster CPS score.</p>
    <h2>How is click speed measured?</h2>
    <p>The standard measurement unit for the click speed test is 5 seconds. However this can be increased according to your preferences.</p>
    <p>To increase the click speed measurement, simply access the menu on the top of the page and select the test speed you would like.</p>
    <p>To change the test speed:</p>
    <ol>
        <li>Go to the menu</li>
        <li>Scroll down to &ldquo;click test speed&rdquo;</li>
        <li>From the available options, select the speed you would like</li>
        <li>You can choose between click per 10 seconds, 15 seconds, 30 seconds, 60 seconds, up to a maximum of one click in 100 seconds</li>
    </ol>
    <h2>How is CPS calculated?</h2>
    <p>CPS stands for clicks per second. This is your click rate, which shows how fast you are at clicking a mouse. A higher CPS rate indicates a better result, and this is also the result you will see when you take the click speed test.</p>
    <p>The platform used to calculate the CPS rate is Element Speed, which is available through either the website or app.&nbsp;</p>
    <p>It uses the following formula to calculate CPS (clicks per second):</p>
    <p><strong>CPS = Number of Clicks / Number of Seconds</strong></p>
    <p>That is, CPS is the number of clicks divided by the number of seconds taken to make the clicks. This will then tell you how fast you are clicking your mouse.</p>
    <h2>Are there other options for a click speed test?</h2>
    <p>You will find if you do a quick Google search that there are a large number of different results for click speed tests. If you have a smartphone or browser, you&rsquo;ll find you have plenty of options for calculating your click speed either on websites or Android apps.</p>
    <p>However, you should be aware that some of these sites and apps are insecure, and may install malware or spyware on your phone or device. Be sure to only use reputable sites and applications, and always read the privacy policies and terms and conditions.</p>
    <h2>Have you heard of the Kohi click test?</h2>
    <p>The Kohi click test is an online tool that helps you to improve your CPS rate. It is named after Kohi, the Minecraft Server renowned for Hard Core Factions Game modes. Minecraft fans and other gamers know how important click speed is when gaming, and so this tool has developed to help Minecraft gamers to check and improve their click speed and accuracy. The click speed test takes the same principles and has created a more refined and effective way to test your CPS and improve your skills.</p>
    <h2>How can I improve my CPS rate?</h2>
    <p>CPS rate can be affected by the speed of your internet connection, as well as the efficiency of your device. However, to achieve the best CPS it is recommended to use a PC, such as a laptop or desktop computer.</p>
    <p>You can use the click speed test to refine your skills and improve your mouse click speed and achieve a higher score over time.</p>
    <p>Here are the different methods to improve CPS click speed:</p>
    <ol>
        <li>Regular Clicking: You can train yourself to improve your mouse click speed through working on your regular clicking as part of conducting daily routine tasks such as editing documents, web browsing, and so on. Regular clicking typically produces a CPS score of 3-6 clicks per second with practice.</li>
        <li>Jitter Clicking: This is a more challenging but more effective way to improve your CPS rate. A more advanced method, it involves shaking the arm and wrist muscles to click the mouse as fast as possible. This creates a vibration in the hand that is known as the jitter affect. This can produce a CPS rate between 10 and 14 CPS.</li>
        <li>Butterfly Clicking: An even faster technique, butterfly clicking can produce a click speed of 15 to 25 CPS.&nbsp;</li>
        <li>Drag Clicking: Drag clicking is one of the fastest clicking techniques, with click speeds from 25 up to an incredible 100 CPS. This technique involves moving your finger from the top to the edge of the mouse button, so that when you move your finger it stimulates mouse clicks.</li>
    </ol>`,
};
let clickTest10 = {
  content: `<p>Click speed is vital for anyone who uses a computer, as it impacts on your efficiency &ndash; the faster you click, the faster you get tasks done! In particular, click speed is vital for gamers, as a high CPS (click per second rate) means you can fight better, build quicker, and overall deliver a superior performance to defeat your enemies or competition.</p>
    <p>The 10 second click test is ideal for gamers, because it is long enough to ensure that a comprehensive and honest CPS (clicks per second) rate is measured. On the other hand, tests greater than 10 seconds can produce a sub-standard result, as your finger may become strained due to repetitive clicking and therefore slow down. Therefore we generally recommend the 10 second click test particularly for gamers.</p>`,
};
let clickTest15 = {
  content: `<p>Want to test your mouse click speed to see how fast you are performing when you are working on your computer, or playing computer games? Take the result of your 15 second click test and multiply it by four to determine your click rate per minute. To know your rate in clicks per second (CPS), take your click per minute rate and divide it by 60.</p>
    <p>Take our 15 second click test to find out your mouse click speed in clicks per second (CPS).</p>`,
};
let clickTest30 = {
  content: `<p>If you are working on improving your click speed, whether training in methods such as jitter clicking, butterfly clicking, or drag clicking, it is important to take accurate click tests to track your progress. Take the result of your 30 second click test and multiply it by two to determine your click rate per minute. To know your rate in clicks per second (CPS), take your click per minute rate and divide it by 60.</p>
    <p>Start the 30 second click test &nbsp;by clicking the button below!</p>`,
};
let clickTest60 = {
  content: `You can use the 60 second click test on either your computer or smart phone and either with your mouse or your keyboard to find out your click speed.
    Take the test below and the result will tell you your current click rate per minute. To know your rate in clicks per second (CPS), take your click per minute rate and divide it by 60.
    What are you waiting for? Take the test now and start your journey to a faster click speed!
    `,
};
let clickTest100 = {
  content: `If you’re looking for a long-form method to check your mouse click speed, you can’t get any more comprehensive than the 100 second click test. You can use this test to calculate your click speed in clicks per second: simply take the result given at the end of the test and divide it by 100 to arrive at your CPS. To know your rate in clicks per minute, multiply your CPS by 60.
    Are you ready? Take the 100 second click test below.`,
};
let jitterClickTest = {
  content: `Jitter clicking is an advanced clicking technique that can greatly improve your mouse click rate compared to regular clicking. With jitter clicking, users can achieve a high click rate of 10 to 14 CPS. This technique uses shakes in the arm muscles and wrist, using these vibrations to click the mouse as quickly as possible. This advanced technique does, however, take a little practice. It is therefore important to take the jitter click test and regularly check your progress over time to monitor your practice and improvement until your achieve perfection!
    Take the jitter click test now to test your jitter click rate. 
    `,
};
let kohiClickTest = {
  content: `Kohi is a server of the legendary Minecraft server well-known for serving Hard Core Factions Game modes of Minecraft. Fans of Minecraft know all too well how important mouse click speed is when playing the game. Faster clicks mean you can fight, move, mine, and build faster, making your gameplay infinitely more successful. The same is equally true for other computer games. It is critical to achieve a high clicks per second (rate) if you want to be successful and triumph over the competition.
    The Kohi speed tests helps you to measure how fast you can click your mouse in order to improve your Minecraft performance, as well as better performance in other games.`,
};

module.exports = {
  clickTest10,
  clickTest15,
  clickTest30,
  clickTest60,
  clickTest100,
  clickTest,
  jitterClickTest,
  kohiClickTest,
};
