import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class RightSidebar extends Component {
  render() {
    if (this.props.test === "normal")
      return (
        <div className="right-sidebar">
          <div class="card my-4">
            <h5 class="card-header">Set timer</h5>
            <div class="card-body">
              <div
                className="btn btn-primary"
                onClick={() => this.props.setTimer(1)}
              >
                1 second
              </div>
              <div
                className="btn btn-primary"
                onClick={() => this.props.setTimer(2)}
              >
                2 second
              </div>
              <div
                className="btn btn-primary"
                onClick={() => this.props.setTimer(5)}
              >
                5 second
              </div>
              <Link className="btn btn-primary" to="/10-second-click-test/">
                10 second
              </Link>
              <Link className="btn btn-primary" to="/15-second-click-test/">
                15 second
              </Link>
              <Link className="btn btn-primary" to="/30-second-click-test/">
                30 second
              </Link>
              <Link className="btn btn-primary" to="/60-second-click-test/">
                60 second
              </Link>
              <Link className="btn btn-primary" to="/100-second-click-test/">
                100 second
              </Link>
            </div>
          </div>
        </div>
      );
    else {
      return (
        <div className="right-sidebar">
          <div class="card my-4">
            <h5 class="card-header">Set timer</h5>
            <div class="card-body">
              <Link
                className="btn btn-primary"
                to="/"
                onClick={() => this.props.setTimer(1)}
              >
                1 second
              </Link>
              <Link
                className="btn btn-primary"
                to="/"
                onClick={() => this.props.setTimer(2)}
              >
                2 second
              </Link>
              <Link
                className="btn btn-primary"
                to="/"
                onClick={() => this.props.setTimer(5)}
              >
                5 second
              </Link>
              <Link className="btn btn-primary" to="/10-second-click-test/">
                10 second
              </Link>
              <Link className="btn btn-primary" to="/15-second-click-test/">
                15 second
              </Link>
              <Link className="btn btn-primary" to="/30-second-click-test/">
                30 second
              </Link>
              <Link className="btn btn-primary" to="/60-second-click-test/">
                60 second
              </Link>
              <Link className="btn btn-primary" to="/100-second-click-test/">
                100 second
              </Link>
            </div>
          </div>
        </div>
      );
    }
  }
}
