import React, { Component } from "react";
import Timer from "react-compound-timer";

export default class ResultArea extends Component {
  render() {
    return (
      <div className="row result-area">
        <Timer
          initialTime={0}
          direction="forward"
          timeToUpdate={10}
          checkpoints={[
            {
              time: this.props.seconds * 1000,
              callback: () => {},
            },
          ]}
          startImmediately={this.props.score >= 1 ? true : false}
          onStart={() => console.log("onStart hook")}
        >
          {({ start, resume, pause, stop, reset, timerState }) => (
            <React.Fragment>
              {console.log(this.props.second, this.props.second)}
              {this.props.second === this.props.second ? stop : null}
              <div className="col-lg-4">
                <div className="card">
                  {this.props.score !== 0 ? (
                    <div className="count">
                      {this.props.seconds}:<Timer.Milliseconds />
                    </div>
                  ) : null}
                  <div className="label">Timer</div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card">
                  {this.props.score !== 0 ? (
                    <div className="count">{this.props.clickPerSecond}</div>
                  ) : null}
                  <div className="label">Click/s</div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card">
                  {this.props.score !== 0 ? (
                    <div className="count">{this.props.score}</div>
                  ) : null}
                  <div className="label">Score</div>
                </div>
              </div>
            </React.Fragment>
          )}
        </Timer>
      </div>
    );
  }
}
