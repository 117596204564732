import React, { Component } from "react";

export default class ResultModal extends Component {
  constructor() {
    super();
    this.state = {
      animal: "",
      image: "",
      quote: "",
    };
  }
  componentDidMount() {
    if (this.props.clickPerSecond <= 5) {
      this.setState({
        animal: "Turtle",
        image: "/assets/images/turtle.png",
        quote: "Stop feeling sorry for yourself, Dont be a looser",
      });
    } else if (
      this.props.clickPerSecond > 5 &&
      this.props.clickPerSecond <= 10
    ) {
      this.setState({
        animal: "Mouse",
        image: "/assets/images/mouse.png",
        quote: "Smart but not fast, flicker your finger even faster.",
      });
    } else if (this.props.clickPerSecond > 10) {
      this.setState({
        animal: "Rabbit",
        image: "/assets/images/rabbit.png",
        quote:
          "Your fingers snap at blistering speed just like the speedie cat runs. Hail to the king of clicking.",
      });
    }
  }
  render() {
    return (
      <div
        class="result-modal modal"
        style={{ display: "block", overflow: "scroll" }}
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title"></h5>

              <button class="close" onClick={() => this.props.toggleModal()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row result-section">
                <div className="col-lg-5 image">
                  <img src={this.state.image} />
                </div>
                <div className="col-lg-7 result">
                  <h1>Your Rank is {this.state.animal}</h1>
                  <p>
                    You Clicked with the speed of {this.props.clickPerSecond}{" "}
                    CPS
                  </p>
                  <p>
                    {this.props.score} Clicks in {this.props.second}{" "}
                    {this.props.second === 1 ? "Second" : "Seconds"}
                  </p>
                </div>
              </div>
              <div className="row quote-section">
                <h2>{this.state.quote}</h2>
              </div>
            </div>
            <div class="modal-footer">
              <button
                className="btn btn-dark"
                onClick={() => this.props.toggleModal()}
              >
                Try again
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
