import React, { Component } from "react";

export default class ClickArea extends Component {
  render() {
    return (
      <div class="click-area">
        <button onClick={() => this.props.incrementClick()}>
          Click here to start playing
        </button>
      </div>
    );
  }
}
