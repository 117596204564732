import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer class="py-5">
        <div class="container">
          <p class="m-0 text-center">
            Copyright &copy; &nbsp;
            <a href="https://clickspeedtest.co">Clickspeedtest</a>&nbsp;2020
          </p>
        </div>
      </footer>
    );
  }
}
