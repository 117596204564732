import React, { Component } from "react";

export default class NotificationArea extends Component {
  render() {
    return (
      <div className="notification">
        {this.props.test === "jitter" ? (
          <div>
            <h1>Jitter click test</h1>
            <h4>10 second click test</h4>
          </div>
        ) : this.props.test === "kohi" ? (
          <div>
            <h1>Kohi click test</h1>
            <h4>10 second click test</h4>
          </div>
        ) : (
          <h1>{this.props.second} second click test</h1>
        )}
      </div>
    );
  }
}
