import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class Navigation extends Component {
  constructor() {
    super();
    this.state = {
      showMouseClickerOptions: false,
    };
  }
  toggleMouseClickerOptions = () => {
    this.setState({
      showMouseClickerOptions: !this.state.showMouseClickerOptions,
    });
  };
  render() {
    return (
      <nav class="navbar navbar-expand-lg fixed-top">
        <div class="container" onClick={this.props.emergencyStopTimer}>
          <Link class="navbar-brand" to="/">
            Clickspeedtest
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ml-auto">
              <li
                class={`nav-item  ${
                  this.props.location.pathname === "/" ? "active" : ""
                }`}
              >
                <Link class="nav-link" to="/">
                  Home
                </Link>
              </li>

              <li
                class={`nav-item  ${
                  this.props.location.pathname === "/1-second-click/"
                    ? "active"
                    : ""
                }`}
                onMouseEnter={() => this.toggleMouseClickerOptions()}
                onMouseLeave={() => this.toggleMouseClickerOptions()}
                style={{ cursor: "pointer" }}
              >
                <div class="nav-link">
                  Mouse clicker
                  <img
                    src="/assets/images/icons/dropdown.png"
                    style={{
                      width: "1rem",
                      marginLeft: ".4rem",
                    }}
                  />
                </div>
                {this.state.showMouseClickerOptions ? (
                  <div className="mouse-clicker-options">
                    <li class="nav-item">
                      <Link
                        class="nav-link"
                        onClick={this.toggleMouseClickerOptions}
                        to="/10-second-click-test/"
                      >
                        10 second click test
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        class="nav-link"
                        onClick={this.toggleMouseClickerOptions}
                        to="/15-second-click-test/"
                      >
                        15 second click test
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        class="nav-link"
                        onClick={this.toggleMouseClickerOptions}
                        to="/30-second-click-test/"
                      >
                        30 second click test
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        class="nav-link"
                        onClick={this.toggleMouseClickerOptions}
                        to="/60-second-click-test/"
                      >
                        60 second click test
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        class="nav-link"
                        onClick={this.toggleMouseClickerOptions}
                        to="/100-second-click-test/"
                      >
                        100 second click test
                      </Link>
                    </li>
                  </div>
                ) : null}
              </li>

              <li
                class={`nav-item  ${
                  this.props.location.pathname === "/jitter-click-test/"
                    ? "active"
                    : ""
                }`}
              >
                <Link class="nav-link" to="/jitter-click-test/">
                  Jitter click test
                </Link>
              </li>
              <li
                class={`nav-item  ${
                  this.props.location.pathname === "/kohi-click-test/"
                    ? "active"
                    : ""
                }`}
              >
                <Link class="nav-link" to="/kohi-click-test/">
                  Kohi click test
                </Link>
              </li>

              <li
                class={`nav-item  ${
                  this.props.location.pathname === "/about-us/" ? "active" : ""
                }`}
              >
                <Link class="nav-link" to="/about-us/">
                  About
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
export default withRouter(Navigation);
